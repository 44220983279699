import { Component, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { FeeDetails } from 'src/app/core/models/fee-details.model';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';
import { BuyCardService } from '../../services/buy-card.service';

@Component({
	selector: 'app-fee-details-modal',
	templateUrl: './fee-details-modal.component.html',
	styleUrls: ['./fee-details-modal.component.scss'],
})
export class FeeDetailsModalComponent implements OnInit, OnDestroy {
	@ViewChildren('scrollableContainer') scrollableContainer!: QueryList<ElementRef>;
	dialogRef: MatDialogRef<FeeDetailsModalComponent>;
	detailIdSelected!: number;
	feeDetails!: FeeDetails[];
	fee!: any;
	isLoading = false;
	indexSelected = 0;
	checkHeigh: boolean = false;
	destroy$ = new Subject<void>();
	private eventListeners: (() => void)[] = [];

	constructor(
		readonly buyCardService: BuyCardService,
		dialogRef: MatDialogRef<FeeDetailsModalComponent>,
		private el: ElementRef,
		private renderer: Renderer2,
		readonly accessibilityService: AccessibilityService,
	) {
		this.dialogRef = dialogRef;
	}

	checkHeighValue(event: any, detail: any) {
		this.scrollableContainer.forEach((scrollDiv, index) => {
			const scrollableElement = scrollDiv.nativeElement;
			detail.hasScroll = true;

			scrollableElement.scrollTop += 1; //fix scroll para mostrar not--end

			if (scrollableElement.scrollHeight >= scrollableElement.clientHeigh) {
				detail.hasScroll = true;
			} else {
				detail.hasScroll = false;
			}

			this.renderer.listen(scrollableElement, 'scroll', () => {
				const atBottom = scrollableElement.scrollTop + scrollableElement.clientHeight >= scrollableElement.scrollHeight;
				detail.hasScroll = !atBottom;
			});
		});
	}

	ngOnInit(): void {
		this.isLoading = true;
		this.buyCardService.langChangeSubject$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (change) => {
				if (!!change) {
					this.buyCardService.fees$.pipe(takeUntil(this.destroy$)).subscribe({
						next: (fee) => {
							if (fee.feeDetails) {
								this.fee = fee.feeDetails;
								this.isLoading = false;
							}
						},
						error: () => {},
					});
				}
			},
			error: () => {},
		});

		if (this.accessibilityService.getAccessibility())
			this.renderer.addClass(this.el.nativeElement, 'modal-accessibility');
	}

	trackByFeeDetailID(index: any, feeDetail: FeeDetails): number {
		return feeDetail.product_detail_id;
	}

	subtract() {
		if (this.indexSelected === 0) {
			this.indexSelected = this.fee.details.length - 1;
		} else {
			this.indexSelected > 0 ? this.indexSelected-- : 1;
		}
	}

	add() {
		if (this.indexSelected === this.fee.details.length - 1) {
			this.indexSelected = 0;
		} else {
			this.indexSelected < this.fee.details.length - 1 ? this.indexSelected++ : this.fee.details.length - 1;
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();

		this.eventListeners.forEach((unlisten) => unlisten());
	}
}

import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, interval, Subject, takeUntil, takeWhile } from 'rxjs';
import { PurchaseType } from 'src/app/core/enums/purchase-type.enum';
import { LanguageService } from 'src/app/core/services/language.service';
import { BuyCardService } from 'src/app/features/buy-card/services/buy-card.service';
import { PaymentService } from 'src/app/features/pay/services/payment.service';
import { RechargeBalanceService } from 'src/app/features/recharge-balance/services/recharge-balance.service';
import { AccessibilityService } from 'src/app/shared/services/accessibility.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { IdleService } from 'src/app/shared/services/idle.service';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { LoadingBlockService } from 'src/app/shared/services/loading-block.service';
import { MailService } from 'src/app/shared/services/mail.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { ScannerService } from 'src/app/shared/services/scanner.service';
import { SseService } from 'src/app/shared/services/sse.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { TimeoutService } from 'src/app/shared/services/timeout.service';
import { environment } from 'src/environments/environment';
import { CardType } from '../../enums/card-type.enum';
import { PaymentMethod } from '../../enums/payment-method.model';

export function startValidationNavigation(router: Router, timeoutService: TimeoutService) {
	const step6 = setTimeout(() => {
		router.navigate(['/buy-card/contract/step', 7]);
	}, 2000);
	timeoutService.setTimeout(step6);
}

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy {
	url = '';
	isSending = false;
	step = 0;
	languageOptions: any[] = [];
	showLanguage: boolean = false;
	isRetrying = false;
	CardType = CardType;
	PaymentMethod = PaymentMethod;
	countdown = 5;

	private destroy$ = new Subject<void>();

	constructor(
		private readonly router: Router,
		readonly stepperService: StepperService,
		readonly activatedRoute: ActivatedRoute,
		readonly location: Location,
		readonly purchaseService: PurchaseService,
		private readonly keyboardService: KeyboardService,
		private readonly mailService: MailService,
		readonly accessibilityService: AccessibilityService,
		readonly errorService: ErrorService,
		private http: HttpClient,
		readonly languageService: LanguageService,
		private readonly timeoutService: TimeoutService,
		readonly loadingBlockService: LoadingBlockService,
		readonly scannerService: ScannerService,
		private readonly buyCardService: BuyCardService,
		readonly rechargeBalanceService: RechargeBalanceService,
		readonly idleService: IdleService,
		private readonly paymentService: PaymentService,
		private readonly eventSourceService: SseService,
	) {}

	ngOnInit(): void {
		this.languageService.allLanguages$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (languages) => {
				this.languageOptions = languages;
				if (this.languageOptions.length > 0) {
					this.showLanguage = true;
				}
			},
			error: () => {
				this.languageOptions = [
					{
						code: 'es',
						label: 'Español',
						order: 1,
					},
					{
						code: 'en',
						label: 'English',
						order: 2,
					},
				];
				this.showLanguage = true;
			},
		});

		this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe({
			next: (event: any) => {
				const step = event.url.split('step/')[1] ?? event.url.split('pay/')[1] ?? event.urlAfterRedirects.split('step/')[1];
				if (event.url.includes('/buy-card/contract/step/4')) {
					const step7 = setTimeout(() => {
						const purchase = this.purchaseService.getPurchase();
						let step = 7;

						if (purchase && !!purchase.isClientVerified) {
							step = 9;
						}

						this.stepperService.setStep(step);
						this.router.navigate(['/buy-card/contract/step', step]);
					}, 2000);
					this.timeoutService.setTimeout(step7);
				}
				this.stepperService.setStep(Number(step));
				this.url = event.url;
			},
			error: () => {},
		});

		this.stepperService.step$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (step) => {
				this.step = step;
				if (step === 14) {
					const step4 = setTimeout(() => {
						this.router.navigate(['/pay', 11]);
					}, 2000);
					this.timeoutService.setTimeout(step4);
				}
				if (step === 15 || step === 18) this.keyboardService.show();
				if (step === 8) {
					interval(1000)
						.pipe(
							takeUntil(this.destroy$),
							takeWhile(() => this.countdown > 0),
						)
						.subscribe(() => {
							this.countdown--;
						});
				}
			},
			error: () => {},
		});
		this.scannerService.secondPart$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				if (value) {
					this.countdown = 5;
					interval(1000)
						.pipe(
							takeUntil(this.destroy$),
							takeWhile(() => this.countdown > 0),
						)
						.subscribe(() => {
							this.countdown--;
						});
				}
			},
			error: () => {},
		});
	}

	trackByCode(index: any, lang: any): string {
		return lang.code;
	}

	goToContract() {
		this.stepperService.setStep(1);
		this.router.navigate(['buy-card/contract/step/1']);
	}

	continue(feature?: string) {
		if (feature) {
			if (this.stepperService.getStep() === 21) {
				this.stepperService.setStep(this.stepperService.getStep() + 1);
				this.router.navigate(['/recharge-balance/step', this.stepperService.getStep()]);
			}
			if (this.stepperService.getStep() === 20 && this.keyboardService.isFormValid()) {
				if (this.purchaseService.getPurchase().totalAmount === 0) this.purchaseService.setPurchase({ totalAmount: 5 });
				this.purchaseService.setPurchase({ msisdn: this.keyboardService.getKeyboarData().formValues.phone });
				this.keyboardService.setKeyboarData({ isShow: false });
				this.stepperService.setStep(this.stepperService.getStep() + 1);
				this.router.navigate(['/recharge-balance/step', this.stepperService.getStep()]);
			}
			if (
				!this.keyboardService.isFormValid() &&
				Object.keys(this.keyboardService.getKeyboarData().formValues).length === 0
			)
				this.keyboardService.setKeyboarData({ resetValues: true });
		} else {
			//SI RUTA CONTIENE /PAY/10, REDIRIGIR A ACTIVACION DE SIM
			if (this.location.path().includes('/pay/10')) {
				this.errorService.removeError();
				this.errorService.setErrorPayCause('');
				this.stepperService.setStep(6);
				this.router.navigate(['/pay', 6]);
				return;
			}
			this.stepperService.setStep(this.stepperService.getStep() + 1);
			this.router.navigate(['/buy-card/contract/step', this.stepperService.getStep()]);
		}
	}

	sendEmail() {
		if (!this.keyboardService.isFormValid() && Object.keys(this.keyboardService.getKeyboarData().formValues).length === 0)
			this.keyboardService.setKeyboarData({ resetValues: true });

		if (this.keyboardService.isFormValid()) {
			this.isSending = true;
			this.purchaseService.setPurchase({ emailCopy: true, email: this.keyboardService.getKeyboarData().formValues.email });
			this.mailService
				.sendContractEmail()
				.pipe(takeUntil(this.destroy$))
				.subscribe({
					next: () => {
						this.isSending = false;
						this.stepperService.setStep(4);
						this.router.navigate(['/buy-card/contract/step', 4]);
					},
					error: () => {
						this.isSending = false;
					},
				});
		}
	}

	sendEmaileSim() {
		if (!this.keyboardService.isFormValid() && Object.keys(this.keyboardService.getKeyboarData().formValues).length === 0)
			this.keyboardService.setKeyboarData({ resetValues: true });

		if (this.keyboardService.isFormValid()) {
			this.isSending = true;
			this.purchaseService.setPurchase({ emailCopy: true, email: this.keyboardService.getKeyboarData().formValues.email });
			this.stepperService.setStep(14);
			this.router.navigate(['/pay', 14]);
			const step4 = setTimeout(() => {
				this.stepperService.setStep(11);
				this.isSending = false;
			}, 2000);
			this.timeoutService.setTimeout(step4);
		}
	}

	sendEmailLasStep() {
		if (!this.keyboardService.isFormValid() && Object.keys(this.keyboardService.getKeyboarData().formValues).length === 0)
			this.keyboardService.setKeyboarData({ resetValues: true });

		if (this.keyboardService.isFormValid()) {
			this.isSending = true;
			this.purchaseService.setPurchase({
				email: this.keyboardService.getKeyboarData().formValues.email,
				isContractSended: true,
			});
			const purchase = this.purchaseService.getPurchase();
			purchase.purchaseType === PurchaseType.BuyCard
				? this.mailService.sendOrderTicketEmail().subscribe(() => this.goToStep16())
				: this.mailService.sendRechargeTicketEmail().subscribe(() => this.goToStep16());
		}
	}

	sendEmailTicketError(esim?: boolean) {
		const keyboard = this.keyboardService.getKeyboarData();

		//IF EXISTS MAIL, SEND AND REDIRECT TO FINAL FLOW
		if (
			keyboard.formValues.email &&
			keyboard.formValues.email !== '' &&
			keyboard.formValues.repeat_email &&
			keyboard.formValues.repeat_email !== ''
		) {
			this.isSending = true;
			if (esim) {
				this.mailService.sendeSIMEmail().subscribe({
					next: () => {
						this.errorService.removeError();
						this.errorService.setErrorTicketCause(false);
						this.stepperService.setStep(5);
						this.router.navigate(['/pay', 5]);
					},
					error: () => {
						this.isSending = false;
					},
				});
			} else {
				this.mailService.sendOrderTicketEmail().subscribe({
					next: () => {
						this.errorService.removeError();
						this.errorService.setErrorTicketCause(false);
						this.stepperService.setStep(5);
						this.router.navigate(['/pay', 5]);
					},
					error: () => {
						this.isSending = false;
					},
				});
			}
		} else {
			//IF NOT EXISTS MAIL, REDIRECT TO VIEW EMAIL
			this.errorService.removeError();
			this.errorService.setErrorTicketCause(false);
			this.stepperService.setStep(18);
			this.router.navigate(['/pay', 18]);
		}
	}

	goToPolicy() {
		this.stepperService.setStep(5);
		this.router.navigate(['/buy-card/contract/step', this.stepperService.getStep()]);
	}

	changeStep(step: number) {
		const purchase = this.purchaseService.getPurchase();

		if (purchase && !!purchase.isClientVerified) {
			step = 9;
		}

		this.stepperService.setStep(step);
		this.purchaseService.setPurchase({ emailCopy: false });
		this.router.navigate(['/buy-card/contract/step', step]);
	}

	changePayStep(step: number) {
		if (step === 12) {
			this.purchaseService.setPurchase({ isQRsent: true });
			this.stepperService.setStep(step);
			this.router.navigate(['/pay', step]);
		}
		if (step === 13) this.stepperService.setStep(step);
		this.router.navigate(['/pay', step]);
	}

	pay() {
		const purchase = this.purchaseService.getPurchase();
		if (purchase.isPaymentSuccessful) {
			if (purchase.purchaseType === PurchaseType.RechargeBalance) return this.router.navigate(['/pay/4']);
			return purchase.cardType === CardType.Physical
				? this.router.navigate(['/pay/4'])
				: this.router.navigate(['/pay/11']);
		}
		this.purchaseService.setPurchase({
			totalAmount: this.purchaseService.getPurchase().totalAmount,
			remainingAmount: this.purchaseService.getPurchase().totalAmount,
		});
		return this.router.navigate(['/pay/1']);
	}

	retryPay(method: string) {
		if (method === 'CASH') {
			this.stepperService.setStep(8);
			this.errorService.removeError();
			this.router.navigate(['/pay/8']);
			this.purchaseService.setPurchase({ paymentMethod: PaymentMethod.CASH });
			this.buyCardService.updateOrder(PaymentMethod.CASH).subscribe({
				next: () => {},
				error: () => {},
			});

			let order_id = this.purchaseService.getPurchase().orderId || '';
			let purchaseType =
				this.purchaseService.getPurchase().purchaseType === 0
					? 'order'
					: this.purchaseService.getPurchase().purchaseType === 1
						? 'recharge'
						: 'order';

			const url = `${environment.apiUrl}/cash/check-payment-status/${order_id}/${purchaseType}`;
			const options = {};
			const eventNames = ['message'];
			this.paymentService.paymentByCash(this.purchaseService.getPurchase().orderId || '', purchaseType).subscribe({
				next: (data) => {
					this.eventSourceService
						.connectToServerSentEvents(url, options, eventNames)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: (eventMessage: any) => {
								let dataFromEventMessage = JSON.parse(eventMessage.data) ?? eventMessage.data;
								//handle event
								if (dataFromEventMessage && !!dataFromEventMessage.success) {
									this.eventSourceService.close();
									this.errorService.setErrorPayCause('');
									this.errorService.removeError();
									this.purchaseService.setPurchase({ isPaymentSuccessful: true });
									this.step = 3;
									this.stepperService.setStep(3);
									this.router.navigate(['/pay', 3]);
								}

								if (this.purchaseService.getPurchase().remainingAmount !== dataFromEventMessage.remaining) {
									this.purchaseService.setPurchase({
										remainingAmount: dataFromEventMessage.remaining,
									});
								}
								//SI EL PROCESO TERMINA POR TIMEOUT, DEVOLVEMOS ERROR
								if (
									dataFromEventMessage &&
									dataFromEventMessage.success === false &&
									dataFromEventMessage.message.includes('Wait time exceeded')
								) {
									this.eventSourceService.close();
									this.errorService.setErrorCause('FINISH_PROCESS');
									this.errorService.setError();
								}
							},
							error: (error) => {
								this.eventSourceService.close();
								this.errorService.setError();
								this.errorService.setErrorPayCause('FINISH_PROCESS');
							},
						});
				},
				error: (e) => {
					this.errorService.setError();
					this.errorService.setErrorPayCause('CASH');
				},
			});
		} else if (method === 'CARD') {
			this.stepperService.setStep(2);
			this.errorService.removeError();
			this.router.navigate(['/pay/2']);
			this.purchaseService.setPurchase({ paymentMethod: PaymentMethod.CARD });
			this.buyCardService.updateOrder(PaymentMethod.CARD).subscribe({
				next: () => {},
				error: () => {},
			});

			let order_id = this.purchaseService.getPurchase().orderId || '';
			let purchaseType =
				this.purchaseService.getPurchase().purchaseType === 0
					? 'order'
					: this.purchaseService.getPurchase().purchaseType === 1
						? 'recharge'
						: 'order';

			const url = `${environment.apiUrl}/tpv/check-payment-status/${order_id}/${purchaseType}`;
			const options = {};
			const eventNames = ['message'];
			this.paymentService.paymentByCard(this.purchaseService.getPurchase().orderId || '', purchaseType).subscribe({
				next: (data) => {
					this.eventSourceService
						.connectToServerSentEvents(url, options, eventNames)
						.pipe(takeUntil(this.destroy$))
						.subscribe({
							next: (eventMessage: any) => {
								let dataFromEventMessage = JSON.parse(eventMessage.data) ?? eventMessage.data;
								//handle event
								if (dataFromEventMessage && !!dataFromEventMessage.success) {
									this.eventSourceService.close();
									this.errorService.setErrorPayCause('');
									this.errorService.removeError();
									this.purchaseService.setPurchase({ isPaymentSuccessful: true });
									this.step = 3;
									this.stepperService.setStep(3);
									this.router.navigate(['/pay', 3]);
								}

								if (this.purchaseService.getPurchase().remainingAmount !== dataFromEventMessage.remaining) {
									this.purchaseService.setPurchase({
										remainingAmount: dataFromEventMessage.remaining,
									});
								}

								//SI EL PROCESO TERMINA POR TIMEOUT O POR ERROR, DEVOLVEMOS ERROR
								if (
									dataFromEventMessage &&
									(dataFromEventMessage.message.includes('Wait time exceeded') || dataFromEventMessage.success === false)
								) {
									this.eventSourceService.close();
									this.errorService.setErrorCause('FINISH_PROCESS');
									this.errorService.setError();
								}
							},
							error: (error) => {
								this.eventSourceService.close();
								this.errorService.setError();
								this.errorService.setErrorPayCause('FINISH_PROCESS');
							},
						});
				},
				error: (e) => {
					this.errorService.setError();
					this.errorService.setErrorPayCause('CASH');
				},
			});
		}
	}

	finishProcess() {
		this.keyboardService.resetKeyboard();
		this.timeoutService.cancelPendingRequests();
		this.errorService.removeError();
		this.purchaseService.resetPurchase();
		this.accessibilityService.setAccessibility(false);
		this.stepperService.setStep(-1);
		this.timeoutService.clearAllTimeouts();
		this.idleService.clearHardware().subscribe();
		this.router.navigate(['/start/1']);
	}

	retry() {
		this.isRetrying = true;
		this.errorService.setRetrying(true);
		const request = this.errorService.getOriginalRequest();
		if (this.location.path().includes('buy-card/details')) {
			this.errorService.setOriginalRequest(null);
			this.errorService.removeError();
			this.errorService.setRetrying(false);
			return this.router.navigate(['buy-card/1']);
		}

		if (
			request?.url.includes('/esim-tramitation') ||
			request?.url.includes('/esim-tramitation-status') ||
			request?.url.includes('disa-recharge') ||
			request?.url.includes('/disa-recharge-status') ||
			request?.url.includes('prepaid-tramitation') ||
			request?.url.includes('/prepaid-tramitation-status') ||
			request?.url.includes('/product/list')
		) {
			return;
		}

		if (this.location.path().includes('/buy-card/contract/step/8')) {
			if (!request?.url.includes('/create')) this.scannerService.resetScanner().subscribe();
			this.errorService.removeError();
			this.errorService.setErrorCause('');
			this.errorService.setRetrying(false);
			this.timeoutService.clearAllTimeouts();
			this.eventSourceService.close(); //close sse scan
			return this.router.navigate(['/buy-card/contract/step/7']);
		}

		if (request)
			this.http.request(request).subscribe({
				next: (value) => {
					let url;
					const location = this.location.path();
					if (value.type !== 0) {
						if (location.includes('buy-card/2') || location.includes('buy-card/details')) {
							url = location;
						} else {
							const splitted = location.split('/').slice(0, -1);
							splitted.push(this.step + 1 + '');
							url = splitted.join('/');
						}
						this.errorService.setOriginalRequest(null);
						this.errorService.removeError();
						this.router.navigate([url]);
						this.errorService.setRetrying(false);
					}
				},
				error: () => {},
			});
		return;
	}

	goBack() {
		this.location.back();
	}

	goToSelection() {
		this.step = 2;
		this.router.navigate(['/start', this.step]);
	}

	selectLanguage(option: string) {
		this.languageService.setLanguage(option);
		this.goToSelection();
	}

	activateScanner() {
		this.scannerService.setScannerStatus(true);
	}

	ngOnDestroy(): void {
		// this.timeoutRefs.forEach((value) => clearTimeout(value));
		this.timeoutService.clearAllTimeouts();
		this.destroy$.next();
		this.destroy$.complete();
	}

	goToStep16() {
		this.stepperService.setStep(16);
		this.router.navigate(['/pay', 16]);
		this.isSending = false;
	}
}

import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, catchError, finalize, forkJoin, takeUntil, throwError } from 'rxjs';
import { CardType } from 'src/app/core/enums/card-type.enum';
import { PurchaseType } from 'src/app/core/enums/purchase-type.enum';
import { Fee } from 'src/app/core/models/fee.model';
import { KeyboardType } from 'src/app/shared/components/keyboard/keyboard.component';
import { ErrorService } from 'src/app/shared/services/error.service';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { FeeDetailsModalComponent } from './components/fee-details-modal/fee-details-modal.component';
import { BuyCardService } from './services/buy-card.service';

export enum titleColorEnum {
	BLUE = 1,
	GREEN,
	PURPLE,
	PINK,
}
@Component({
	selector: 'app-buy-card',
	templateUrl: './buy-card.component.html',
	styleUrls: ['./buy-card.component.scss'],
	host: {
		class: 'main',
	},
})
export class BuyCardComponent implements OnInit, OnDestroy {
	fees: Fee[] = [];
	feesDetails: Fee[] = [];
	physicalFees!: Fee[];
	eSIMfees!: Fee[];
	myObservable$: Observable<any[]> | undefined;
	colors = ['blue', 'green', 'purple', 'pink'];
	step: number = 1;
	CardType = CardType;
	isLoading = false;
	cardOption!: CardType;
	productIdSelected!: string;
	showItem: boolean = false;

	public translatedHtml: any;

	destroy$ = new Subject<void>();

	constructor(
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly dialog: MatDialog,
		public buyCardService: BuyCardService,
		readonly purchaseService: PurchaseService,
		readonly stepperService: StepperService,
		private readonly keyboardService: KeyboardService,
		private readonly location: Location,
		readonly errorService: ErrorService,
		private readonly renderer: Renderer2,
		private readonly el: ElementRef,
		private readonly trans: TranslateService,
	) {
		if (this.buyCardService.getFeesSubject().fees.length === 0) {
			this.getFees();
		}
	}

	ngOnInit(): void {
		setTimeout(() => {
			this.showItem = true;
		}, 500);
		this.step = Number(this.route.snapshot.paramMap.get('step'));
		this.stepperService.setStep(this.step);

		this.errorService.hasError$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				value
					? this.renderer.addClass(this.el.nativeElement, 'main--loader')
					: this.renderer.removeClass(this.el.nativeElement, 'main--loader');
			},
			error: () => this.renderer.removeClass(this.el.nativeElement, 'main--loader'),
		});

		this.fees =
			this.purchaseService.getPurchase().cardType === 1
				? this.buyCardService.getPhysicalFees().fees
				: this.buyCardService.getEsimFees().fees;

		this.router.events.pipe(takeUntil(this.destroy$)).subscribe({
			next: (event) => {
				if (event instanceof NavigationEnd) {
					if (event.url.includes('buy-card/1')) {
						this.showItem = true;
						this.step = 1;
					}
					if (event.url.includes('buy-card/2')) {
						this.showItem = false;
						this.step = 2;
					}
				}
			},
			error: () => {
				this.showItem = true;
				this.step = 1;
			},
		});

		this.keyboardService.setKeyboarData({
			theme: KeyboardType.IOS,
			fieldFocused: 'email',
			formFields: ['email', 'repeat_email'],
		});

		this.trans.onLangChange.pipe(takeUntil(this.destroy$)).subscribe({
			next: () => {
				if (this.buyCardService.getFeesSubject().fees.length !== 0) {
					this.getFees();
				}
			},
			error: () => {},
		});

		this.errorService.isRetrying$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value) => {
				if (value) {
					const request = this.errorService.getOriginalRequest();
					if (request.url.includes('/product/list')) this.getFees();
				}
			},
			error: () => {},
		});

		// Obtener la traducción de la clave
		this.trans
			.get('ANOTHER_SIM')
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (translation: string) => {
					this.translatedHtml = translation;
					// Luego de aplicar el HTML dinámico, procesamos los enlaces
					this.applyRouterLinks();
				},
				error: () => {},
			});
	}

	trackByProductID(index: any, fee: Fee): string {
		return fee.product_id;
	}

	applyRouterLinks() {
		const element = this.el.nativeElement.querySelector('.dynamic-content');
		if (element) {
			const links = element.querySelectorAll('[data-router-link]');
			links.forEach((link: HTMLElement) => {
				const routerLinkValue = link.getAttribute('data-router-link');
				if (routerLinkValue) {
					this.renderer.listen(link, 'click', (event) => {
						event.preventDefault();
						this.router.navigate([routerLinkValue]);
					});
				}
			});
		}
	}

	getFees() {
		this.isLoading = true;
		this.buyCardService.setLangChangeSubject(false);
		forkJoin([this.buyCardService.getFees('Physical'), this.buyCardService.getFees('eSIM')])
			.pipe(
				catchError((error) => {
					this.isLoading = false;
					return throwError(() => error);
				}),
			)
			.subscribe({
				next: ([physicalFees, eSIMFees]) => {
					this.physicalFees = physicalFees;
					this.buyCardService.setPhysicalFees(physicalFees);
					this.eSIMfees = eSIMFees;
					this.buyCardService.setESimFees(eSIMFees);

					this.fees = this.cardOption === CardType.Physical ? physicalFees : eSIMFees ?? physicalFees;
					this.buyCardService.setFees(this.fees);
					this.isLoading = false;
					this.errorService.setOriginalRequest(null);
					this.errorService.removeError();
					this.errorService.setRetrying(false);
					let allRequests: any = [];
					this.fees.forEach((fee) => {
						allRequests.push(this.buyCardService.getFee(fee.product_id));
					});

					forkJoin(allRequests)
						.pipe(
							finalize(() => {
								this.buyCardService.setLangChangeSubject(true);
							}),
						)
						.subscribe({
							next: (results: any) => {
								this.buyCardService.setFeesDetails(results);
								results.forEach((value: Fee) => {
									if (this.productIdSelected && this.productIdSelected === value.info.product_id) {
										this.buyCardService.setFeeDetails(value);
									}
								});
							},
							error: () => {},
						});
				},
				error: () => {},
			});
	}

	openDialog(fee: any, event: MouseEvent) {
		event.stopPropagation();
		this.productIdSelected = fee.product_id;
		const feeDetails = this.buyCardService.getFeesDetails().find((data) => data.info.product_id === fee.product_id);
		if (feeDetails) this.buyCardService.setFeeDetails(feeDetails);
		this.dialog.open(FeeDetailsModalComponent);
	}

	goToDetails(productId: string, tagColor: string, event: Event) {
		event.stopPropagation();
		const feeSelected = this.fees.find((value) => value.product_id === productId)!;
		this.buyCardService.setFeeSelected(feeSelected);
		this.redirectToPurchase(feeSelected, tagColor);
	}

	selectOption(step: number, option: CardType) {
		this.showItem = false;
		this.step = step;
		this.cardOption = option;

		this.purchaseService.setPurchase({ cardType: option });
		const purchase = this.purchaseService.getPurchase();

		if (!!purchase.orderId)
			this.buyCardService
				.updateProduct(
					purchase.orderId!,
					purchase.productId!,
					purchase.cardType! === 0 ? 'eSIM' : CardType[purchase.cardType! ?? 1],
				)
				.subscribe();

		this.fees =
			option === CardType.Physical ? this.buyCardService.getPhysicalFees().fees : this.buyCardService.getEsimFees().fees;
		this.router.navigate(['buy-card/2']);
	}

	ngOnDestroy(): void {
		if (this.location.path().includes('start/2')) this.buyCardService.setFees([]);

		this.destroy$.next();
		this.destroy$.complete();
	}

	redirectToPurchase(fee: Fee, tagColor: string) {
		let purchase = this.purchaseService.getPurchase();
		if (!!purchase.isAnotherBuy) {
			this.purchaseService.setPurchase({
				feePrice: Number(fee.price),
				feeAmount: Number(fee.price),
				totalAmount: Number(fee.price),
				isContractSended: fee.product_id === purchase.productId! ? true : false,
				feeSubtitle: fee.subtitle,
				remainingAmount: Number(fee.price),
				feeCount: 1,
				purchaseType: PurchaseType.BuyCard,
			});

			if (fee.product_id !== purchase.productId!) {
				this.buyCardService.updateProduct(purchase.orderId!, fee.product_id!).subscribe(() => {});
				this.purchaseService.setPurchase({ isDistinctProduct: true, productId: fee.product_id! });
				this.updateOrder(purchase);
				this.router.navigate(['buy-card/details', fee.product_id], {
					queryParams: { tagColor: tagColor },
				});
				return;
			} else {
				this.purchaseService.setPurchase({ isDistinctProduct: false });
				this.updateOrder(purchase);
				this.router.navigate(['/buy-card/contract/step/9']);
			}
			return;
		} else {
			this.purchaseService.setPurchase({
				productId: fee.product_id,
				feePrice: Number(fee.price),
				feeAmount: Number(fee.price),
				totalAmount: Number(fee.price),
			});
			if (this.stepperService.getIsOnPurchaseResume()) {
				this.stepperService.setIsOnPurchaseResume(false);
				this.updateOrder(purchase);
				this.router.navigate(['/buy-card/contract/step/9']);
				return;
			}
		}

		this.updateOrder(purchase);

		this.router.navigate(['buy-card/details', fee.product_id], {
			queryParams: { tagColor: tagColor },
		});
	}

	updateOrder(purchase: any) {
		if (!!purchase.orderId) {
			this.buyCardService
				.updateOrder(
					undefined,
					this.purchaseService.getPurchase().productId,
					this.purchaseService.getPurchase().feeAmount,
					this.purchaseService.getPurchase().totalAmount,
				)
				.subscribe();
		}
	}
}
